
import { TextField } from "@mui/material";
import { useCallback } from "react";


const DEFAULT_EV = { target: { value: null } };


const SELECT_ON_FOCUS = (ev) => {
  ev?.target?.select();
};


export default function TextFieldDecimal(props) {
  const { value, onChange, label, InputProps, disabled, nullable, integer, decimal, selectOnFocus } = props;

  const handleChange = useCallback((ev) => {
    if (!ev.target.validity.badInput) {
      const value = ev.target.value;

      if (decimal) {
        onChange(ev);
      } else if (nullable && value === "") {
        onChange(DEFAULT_EV, null);
      } else {
        onChange(DEFAULT_EV, integer ? parseInt(value || "0") : Number(value || "0"));
      }
    }
  }, [onChange, nullable, integer, decimal]);


  return (
    <TextField
      label={label}
      onChange={handleChange}
      value={value === null ? "" : value}
      onFocus={selectOnFocus ? SELECT_ON_FOCUS : undefined}
      variant="filled"
      fullWidth
      type="number"
      size="small"
      InputProps={InputProps}
      disabled={disabled}
    />
  );
}

